module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en","es","fr","it"],"defaultLanguage":"de","siteUrl":"https://ambrosia-rueckersdorf.de","trailingSlash":"always","i18nextOptions":{"fallbackLng":"de","supportedLngs":["de","en","es","fr","it"],"redirect":false,"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ambrosia Restaurant","short_name":"Ambrosia","description":"Lassen Sie sich in ein mediterranes Flair entführen und genießen Sie griechische Lebensfreude. Wir verwöhnen Sie mit außerlesenen Weinen und exzellenten Gerichten bei gepflegter Atmosphäre.","start_url":"/","icon":"src/images/icon.png","background_color":"#18191a","theme_color":"#18191a","display":"fullscreen","theme_color_in_head":false,"crossOrigin":"use-credentials","orientation":"portrait","lang":"de","categories":["food"],"dir":"ltr","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4483902d0bd14f01ba45425c21233378"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ambrosia-rueckersdorf.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DZN0XE1F8X"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
